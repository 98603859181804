import {
  Add,
  BorderColorOutlined,
  CheckCircleOutline,
  ChevronRight,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import MASTERCARD_IMG from "assets/img/card/mastercard.png";
import VISA_IMG from "assets/img/card/visa.svg";
import LOGO from "assets/img/logo/logo.svg";
import CustomImage from "components/CustomImage";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { BackTitle } from "pages/overview";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { IUserProfile, UserProfileActions } from "redux/model";
import { RootState } from "redux/reducers";
import userService from "services/user.service";
import { APIResponseCode } from "utility/types";

const PaymentMethods = () => {
  const user = useSelector(
    (state: RootState) => state?.userProfile?.user ?? {}
  );
  const dispatch = useDispatch();
  const router = useNavigate();
  const { state } = useLocation();
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);

  const PaymentEditor = ({
    cardNum = "",
    expMonth = 1,
    expYear = 2000,
    cardBrand = "visa",
    selected = false,
    onChangePaymentMethod = () => {},
    onEdit = () => {},
  }: {
    cardNum: string;
    expMonth: number;
    expYear: number;
    cardBrand: string;
    selected: boolean;
    onChangePaymentMethod: () => void;
    onEdit: () => void;
  }) => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <CustomImage
              src={cardBrand === "visa" ? VISA_IMG : MASTERCARD_IMG}
              alt=""
              height={24}
              width={"auto"}
            />
            <CustomText className="font-pSemiBold">****{cardNum}</CustomText>
            <CustomText className="text-mBIcon text-sm">
              (Expiry {expMonth}/{expYear})
            </CustomText>
          </div>
          <div className="flex gap-2">
            <div onClick={onChangePaymentMethod}>
              <CheckCircleOutline color={selected ? "success" : "inherit"} />
            </div>
            <div onClick={onEdit}>
              <BorderColorOutlined />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  };

  const loadPaymentMethods = async () => {
    if (user.customerId) {
      startLoading();
      const res = await userService.getPaymentMethods({
        customerId: user?.customerId ?? "cus_No9vsYz6W6wVNH",
      });
      finishLoading();
      if (res.code === APIResponseCode.SUCCESS) {
        setPaymentMethods(res.data);
      } else {
        toast.error(
          res.message ??
            "Something went wrong. Please contact with support team"
        );
      }
    }
  };

  const handleChangePaymentMethod = async (id: string) => {
    startLoading();
    const res = await userService.update({
      id: user.id,
      data: { id: user.id, paymentMethod: id } as IUserProfile,
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      dispatch({
        type: UserProfileActions.UPDATE_USER_PROFILE,
        payload: { user: { ...user, paymentMethod: id } },
      });
      toast.success("Your primary payment method changed successfully");
    } else toast.warning(res.message);
    dispatch({
      type: UserProfileActions.UPDATE_USER_PROFILE,
      payload: { user: { ...user, paymentMethod: id } },
    });
  };

  const handleEdit = (item: any) => {
    router("/account/payment/edit", { state: { ...state, card: item } });
  };

  useEffect(() => {
    loadPaymentMethods();
    //eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <MobilePaper className="px-4 py-6 flex flex-col gap-4">
        <BackTitle title="Payment Methods" url="" />
        <div className="flex flex-col gap-4 flex-1 basis-0 overflow-y-auto">
          <div className="flex flex-col gap-2">
            <CustomText className="font-pSemiBold text-lg">Wallet</CustomText>
            <div className="flex justify-between p-4 rounded-md bg-bgColor relative overflow-hidden">
              <div className="flex flex-col gap-3">
                <CustomText className="text-sm font-pMedium">
                  Timata Credit Balance
                </CustomText>
                <CustomText className="text-xl font-pSemiBold">
                  NZ ${user.balance}
                </CustomText>
                <Button
                  color="primary"
                  variant="contained"
                  className="normal-case rounded-md z-10"
                  onClick={() =>
                    router("/account/payment/add_credit", {
                      state: { ...state, pathName: "account" },
                    })
                  }
                >
                  <Add />
                  Add Credit with Code
                </Button>
              </div>
              <ChevronRight />
              <CustomImage
                src={LOGO}
                alt=""
                className="absolute -right-[40%] top-0 opacity-10 h-full"
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <CustomText className="text-primary font-pSemiBold capitalize text-lg">
              select payment methods
            </CustomText>
            <div className="flex flex-col gap-4">
              {paymentMethods.map((item, index) => (
                <PaymentEditor
                  key={index}
                  cardNum={item?.card?.last4}
                  expMonth={item?.card?.exp_month}
                  expYear={item?.card?.exp_year}
                  cardBrand={item?.card?.display_brand}
                  selected={item?.id === user?.paymentMethod}
                  onChangePaymentMethod={() =>
                    handleChangePaymentMethod(item?.id)
                  }
                  onEdit={() => handleEdit(item)}
                />
              ))}
            </div>
            <div
              className="border py-3 flex items-center gap-2 border-x-0 cursor-pointer"
              onClick={() => router("/checkout/payment/add")}
            >
              <Add />
              <CustomText className="capitalize text-lg font-pSemiBold">
                add payment methods
              </CustomText>
            </div>
          </div>
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default PaymentMethods;
