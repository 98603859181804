import { Button } from "@mui/material";
import AddressPickerComponent from "components/AddressPickerComponent";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import {
  IUserProfile,
  UserProfileActions,
  addressRightProps,
} from "redux/model";
import userService from "services/user.service";
import { APIResponseCode } from "utility/types";

const EditAddress = ({
  user = {} as IUserProfile,
  onSuccess = () => {},
}: {
  user: IUserProfile;
  onSuccess: () => void;
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<{ address?: string }>({});
  const [address, setAddress] = useState<addressRightProps>();

  const handleUpdate = async () => {
    if (!address?.id) return setError({ address: "This field is required" });
    else setError({});
    startLoading();
    const res = await userService.update({
      id: user.id,
      data: { ...user, address: address },
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      toast.success("Address successfully changed.");
      dispatch({
        type: UserProfileActions.UPDATE_USER_PROFILE,
        payload: { user: res.data ?? {} },
      });
      onSuccess();
    } else
      return toast.error(
        res?.message ?? "Something went wrong. Please try again."
      );
  };

  useEffect(() => {
    setAddress(user.address);
  }, [user]);

  return (
    <div className="flex flex-col gap-4">
      <CustomText className="text-lg font-pSemiBold">Address</CustomText>
      <CustomText className="text-sm font-pRegular text-mBIcon">
        Upgrade your address for a personalized experience
      </CustomText>
      <div className="flex flex-col gap-2">
        <AddressPickerComponent
          value={address}
          onChange={(address: addressRightProps) => setAddress(address)}
        />
        {error ? <CustomText>{error.address}</CustomText> : null}
      </div>

      <Button
        fullWidth={true}
        color="primary"
        variant="contained"
        size="large"
        className="capitalize"
        onClick={handleUpdate}
      >
        Update
      </Button>
    </div>
  );
};

export default EditAddress;
